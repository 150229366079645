import {createGlobalStyle} from 'styled-components'

export default createGlobalStyle`
*{
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  color: #fafafa
}

body {
  background: #1b1b1b;
  -webkit-font-smoothing: antialiased;
}

body, input, button {
  font: 16px Roboto, sans-serif;
}

#root {
  max-width: 100%;
  overflow-x: hidden;
  margin: 0 auto;
  padding: ${window.innerWidth > 960 ? '30px 0px' : 0}
}

button {
  cursor: pointer;
}

`;
