export const projetos =
  {
    moda: [
    {
      title: 'FARM',
      subtitle: 'REFLORESTA',
      subtitle2: 'Campanha oficial de mídias',
      url: 'farmrefloresta',
      capa: 'https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20projetos/Captura%20de%20Tela%202021-03-01%20%C3%A0s%2015.54.17.jpg',
    },
    {
      title: 'FARM',
      subtitle: 'Alto verão 21',
      capa: 'https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20alto%20verao/6.jpg',
      url: 'farmaltoverao21',
    },
    {
      title: 'Farm Rio',
      subtitle: 'Fall 20',
      url: 'farmriofall20',
      capa: 'https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20projetos/1%20-%20DSC06740.jpg',
    },
    {
      title: 'Maria Filó',
      subtitle: 'Verão 21',
      url: 'mariafiloverao21',
      capa: 'https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20projetos/4%20-%20PMG02514.jpg',
    },
    {
      title: 'Animale Oro',
      subtitle: 'Secret Garden',
      url: 'animaleorosecretgarden',
      capa: 'https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20projetos/4%20-%20DSC08877-Edit.jpg',
    },



    {
      title: 'Animale',
      subtitle: 'Viajantes do futuro',
      subtitle2: 'Desfile',
      url: 'animaleviajantesdefile',
      capa: 'https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20viajantes%20do%20tempo/5%20-%20CAPA.jpg',
    },

  ],
  branded:
     [
      {
        title: 'Budweiser X Bar Mãe Joana',
        capa: 'https://storage.googleapis.com/firma_avi/PROJETOS/BRANDED/1%20-%20BUDWEISER%20X%20BAR%20M%C3%83E%20JOANA/bud.jpg',
        url: 'budmaejoana',

      },
      {
        title: 'BUDWEISER',
        subtitle: 'BRUNO BIG X BAR 399',
        capa: 'https://storage.googleapis.com/firma_avi/PROJETOS/BRANDED/2%20-%20BUDWEISER%20BRUNO%20BIG%20X%20BAR%20399/CAPA.jpg',
        url: 'budbig399',

      },
      {
        title: '399, O RETORNO',
        capa: 'https://storage.googleapis.com/firma_avi/PROJETOS/BRANDED/3%20-%20399%2C%20O%20RETORNO!/399.jpg',
        url: 'oretorno399',
      },
    ],
  streaming: [
    {
      title: 'FARM Green Friday',
      subtitle: 'Live Shop',
      capa: 'https://storage.googleapis.com/firma_avi/PROJETOS/STREAMING/FARM/2%20-%20PMG02527%20-%20CAPA.jpg',
      url: 'farmgreenfriday',
    },
    {
      title: 'Live Animale',
      subtitle: 'Rio de Janeiro 2020',
      url: 'liveanimalerj2020',
      capa: 'https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20animale%20joa/2%20-%20capa.jpg',
    },
    {
      title: 'ANIMALE EXPERIENCE',
      capa: 'https://storage.googleapis.com/firma_avi/CAPA3/2%20-%20PMG00111%20-%20CAPA.jpg',
      url: 'animaleexperience',
    },
  ],
  entretenimento: [
    {
      title: 'BAILE DO ENCANTO',
      capa: 'https://storage.googleapis.com/firma_avi/soltas/CAPAS_GIF/1%20-%20CAPA%20-%20GIF%202.mp4',
      url: 'bailedoencanto',
    },
    {
      title: 'FESTA DUBLACK',
      url: 'festadublack',
      capa: 'https://storage.googleapis.com/firma_avi/soltas/CAPAS_GIF/dancebro.mp4',
    },
  {
      title: 'SPANTA',
      url: 'spanta',
      capa: 'https://storage.googleapis.com/firma_avi/CAPA3/2%20-%20capa%20-PMG03648.jpg',

    },

  ],
  outros: [
    // {
    //   title: 'SKYBROWN',
    //   subtitle: 'A fight for a light in Favela da Mare',
    //   capa: 'https://storage.googleapis.com/firma_avi/PROJETOS/OUTRAS/1%20-%20SKYBROWN%20A%20fight%20for%20a%20light%20in%20Favela%20da%20Mare/CAPA.jpg',
    //   url: 'skybrown',
    // },
    {
      title: 'FERRUGEM feat ANCHIETX',
      capa: 'https://storage.googleapis.com/firma_avi/PROJETOS/OUTRAS/2%20-%20FERRUGEM%20feat%20ANCHIETX%20-%20DJAVAN_videovimeo_fotos/2%20-%20capa.jpg',
      url: 'ferrugemanchietx',
    },
    {
      title: 'JAPA Dejavu',
      capa: 'https://storage.googleapis.com/firma_avi/CAPA3/2%20-%20CAPA.jpg',
      url: 'japa',
    },
  ]
};

export const marcas = {
  farmrefloresta: [
    'https://storage.googleapis.com/firma_avi/PROJETOS/MODA/FARM%20REFLORESTA/gif%2040_.mp4',
    "https://storage.googleapis.com/firma_avi/PROJETOS/MODA/FARM%20REFLORESTA/gif%2019_.mp4",
    "https://storage.googleapis.com/firma_avi/PROJETOS/MODA/FARM%20REFLORESTA/gif16.mp4",
    "https://storage.googleapis.com/firma_avi/PROJETOS/MODA/FARM%20REFLORESTA/FARM%2010-02%20SITE.mp4",
    "https://storage.googleapis.com/firma_avi/PROJETOS/MODA/FARM%20REFLORESTA/gif%2037.mp4",


  ],
  farmaltoverao21: [
    "https://storage.googleapis.com/firma_avi/Home/FARM/3.mov",
    "https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20alto%20verao/Captura%20de%20Tela%202021-03-01%20%C3%A0s%2014.20.29.jpg",
    "https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20alto%20verao/DSC04356.jpg",
    "https://storage.googleapis.com/firma_avi/Home/FARM/7.mp4",
    "https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20alto%20verao/5.jpg",
    "https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20alto%20verao/DSC02878.jpg",
    "https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20alto%20verao/DSC03301.jpg",
    "https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20alto%20verao/6.jpg",
    "https://storage.googleapis.com/firma_avi/Home/FARM/8.mp4",
    "https://storage.googleapis.com/firma_avi/Home/FARM/4.mp4",
    "https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20alto%20verao/1%20-%20capa.jpg",
  ],
  farmgreenfriday: [
    "https://storage.googleapis.com/firma_avi/PROJETOS/STREAMING/FARM/2%20-%20PMG02527%20-%20CAPA.jpg",
    "https://storage.googleapis.com/firma_avi/PROJETOS/STREAMING/FARM/3%20-%20PMG01033.jpg",
    "https://storage.googleapis.com/firma_avi/PROJETOS/STREAMING/FARM/4%20-%20PMG02857.jpg",
    "https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20green%20friday/5%20-%20PMG00836-2.jpg",
  ],
  liveanimalerj2020: [
    'https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20animale%20joa/2%20-%20capa.jpg',
    'https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20animale%20joa/51.jpg',
    'https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20animale%20joa/PMG07935.jpg',
    'https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20animale%20joa/4.jpg',
    'https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20animale%20joa/6.jpg',
    'https://storage.cloud.google.com/firma_avi/soltas/imagens%20soltas%20-%20animale%20joa/PMG08368.jpg',
  ],
  animaleexperience: [
    'https://storage.googleapis.com/firma_avi/PROJETOS/STREAMING/ANIMALEEXPERIENCE/4%20-%20PMG00164.jpg',
        'https://storage.googleapis.com/firma_avi/PROJETOS/STREAMING/ANIMALEEXPERIENCE/5%20-%20PMG00365.jpg',
        'https://storage.googleapis.com/firma_avi/PROJETOS/STREAMING/ANIMALEEXPERIENCE/3-%20PMG09580.jpg',

  ],
  budmaejoana:[
    "https://storage.googleapis.com/firma_avi/PROJETOS/BRANDED/1%20-%20BUDWEISER%20X%20BAR%20M%C3%83E%20JOANA/CAPA.jpg",
  ],
  budbig399: [
    "https://storage.googleapis.com/firma_avi/PROJETOS/BRANDED/2%20-%20BUDWEISER%20BRUNO%20BIG%20X%20BAR%20399/CAPA.jpg",
  ],
  'oretorno399': [
    'https://storage.googleapis.com/firma_avi/PROJETOS/BRANDED/3%20-%20399%2C%20O%20RETORNO!/CAPA.jpg',
  ],
  bailedoencanto:[
    "https://storage.googleapis.com/firma_avi/PROJETOS/ENTRETENIMENTO/1ENCANTO/1%20-%20GIF%202.mp4",
    "https://storage.googleapis.com/firma_avi/PROJETOS/ENTRETENIMENTO/1ENCANTO/2%20-%20GIF%204_.mp4",
    "https://storage.googleapis.com/firma_avi/PROJETOS/ENTRETENIMENTO/1ENCANTO/3%20-%20GIF%20mulher%20de%20vermelho_.mp4",
    "https://storage.googleapis.com/firma_avi/PROJETOS/ENTRETENIMENTO/1ENCANTO/4%20-%20GIF%201.mp4",
    "https://storage.googleapis.com/firma_avi/PROJETOS/ENTRETENIMENTO/1ENCANTO/5%20-%20gif%20frota.mp4",
    'https://storage.googleapis.com/firma_avi/PROJETOS/ENTRETENIMENTO/1ENCANTO/DSC01853.mp4',
  ],
  spanta: [
    "https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20spanta/2%20-%20capa.jpg",
    "https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20spanta/4.jpg",
    "https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20spanta/3.jpg",
    "https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20spanta/GIF%20%20Copy%2012.mp4",
    "https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20spanta/ARRASTA-BROWN_01.jpg",
    "https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20spanta/ARRASTA-BROWN_02.jpg",
    "https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20spanta/GIF%20%20Copy%2003.mp4",
  ],
  festadublack: [
    'https://storage.googleapis.com/firma_avi/PROJETOS/ENTRETENIMENTO/3DUBLACK/dancebro.mp4',
    'https://storage.googleapis.com/firma_avi/PROJETOS/ENTRETENIMENTO/3DUBLACK/heavycoloridao.mp4',
    'https://storage.googleapis.com/firma_avi/PROJETOS/ENTRETENIMENTO/3DUBLACK/nyake%201.mp4',
    'https://storage.googleapis.com/firma_avi/PROJETOS/ENTRETENIMENTO/3DUBLACK/pinkquadradinho.mp4'
  ],
  skybrown: [
    "https://storage.googleapis.com/firma_avi/PROJETOS/OUTRAS/1%20-%20SKYBROWN%20A%20fight%20for%20a%20light%20in%20Favela%20da%20Mare/CAPA.jpg"
  ],
  ferrugemanchietx: [
    "https://storage.googleapis.com/firma_avi/PROJETOS/OUTRAS/2%20-%20FERRUGEM%20feat%20ANCHIETX%20-%20DJAVAN_videovimeo_fotos/2%20-%20capa.jpg",
    'https://storage.googleapis.com/firma_avi/PROJETOS/OUTRAS/2%20-%20FERRUGEM%20feat%20ANCHIETX%20-%20DJAVAN_videovimeo_fotos/3%20-%20DSC00043.jpg',
    'https://storage.googleapis.com/firma_avi/PROJETOS/OUTRAS/2%20-%20FERRUGEM%20feat%20ANCHIETX%20-%20DJAVAN_videovimeo_fotos/4%20-%20DSC01313.jpg',
  ],
  japa: [
    'https://storage.googleapis.com/firma_avi/PROJETOS/OUTRAS/3%20-%20JAPA%20Dejavu/2%20-%20CAPA.jpg',
    'https://storage.googleapis.com/firma_avi/PROJETOS/OUTRAS/3%20-%20JAPA%20Dejavu/4.jpg',
    'https://storage.googleapis.com/firma_avi/PROJETOS/OUTRAS/3%20-%20JAPA%20Dejavu/3.mp4',

  ],
  farmriofall20: [
    'https://storage.googleapis.com/firma_avi/PROJETOS/MODA/FARM/FARM%20RIO%20RESORT%20SITE.mp4',
    'https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20campanha%20farm/3%20-%20DSC06265.jpg',

    "https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20campanha%20farm/1%20-%20DSC06740.jpg",

    // "https://storage.cloud.google.com/firma_avi/PROJETOS/MODA/FARM/gif12_.mp4",

    "https://storage.googleapis.com/firma_avi/PROJETOS/MODA/FARM/gif10_.mp4",
    "https://storage.googleapis.com/firma_avi/PROJETOS/MODA/FARM/5%20-%20gif6_.mp4",

    "https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20campanha%20farm/8%20-%20DSC04601.jpg",


    "https://storage.googleapis.com/firma_avi/PROJETOS/MODA/FARM/4%20-%20gif1_.mp4",
    "https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20campanha%20farm/6%20-%20DSC05560.jpg",


  ],
  mariafiloverao21: [
    'https://storage.googleapis.com/firma_avi/PROJETOS/MODA/MARIAFILO/4%20-%20PMG02514.jpg',
    'https://storage.googleapis.com/firma_avi/PROJETOS/MODA/MARIAFILO/3%20-%20capa%20PMG02507.jpg',
    'https://storage.googleapis.com/firma_avi/PROJETOS/MODA/MARIAFILO/2%20-%20PMG02477.jpg',

  ],
  animaleorosecretgarden: [
    'https://storage.googleapis.com/firma_avi/soltas/oro/2%20-%20DSC09081.jpg',
    'https://storage.cloud.google.com/firma_avi/soltas/oro/DSC09048.jpg',
    'https://storage.googleapis.com/firma_avi/soltas/oro/6%20-%20DSC09055.jpg',

    'https://storage.googleapis.com/firma_avi/soltas/oro/6%20-%20DSC09055.jpg',

    'https://storage.googleapis.com/firma_avi/soltas/oro/5%20-%20DSC09108.jpg',



  ],
  abrandmemoriasafetivas:[
    'https://storage.googleapis.com/firma_avi/PROJETOS/MODA/ABRAND/capa.jpg',
  ],
  animaleviajantesdefile: [
    "https://storage.googleapis.com/firma_avi/PROJETOS/MODA/ANIMALE/5%20-%20CAPA.jpg",
    "https://storage.googleapis.com/firma_avi/PROJETOS/MODA/ANIMALE/1.png",
    "https://storage.googleapis.com/firma_avi/PROJETOS/MODA/ANIMALE/2.jpg",
    "https://storage.googleapis.com/firma_avi/PROJETOS/MODA/ANIMALE/4.jpg",
    "https://storage.googleapis.com/firma_avi/PROJETOS/MODA/ANIMALE/3.jpg",

  ],

}

export const home = [
  {
    title: 'FARM REFLORESTA',
    subtitle: 'Campanha oficial de mídias',
    url: 'farmrefloresta',
    capa: 'https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20refloresta/CAPA.jpg',
  },
  {
    title: 'FARM ALTO VERÃO 21',
    capa: 'https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20alto%20verao/1%20-%20capa.jpg',
    url: 'farmaltoverao21',
  },
  {
    title: 'Live Animale',
    subtitle: 'Rio de Janeiro 2020',
    url: 'liveanimalerj2020',
    capa: "https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20animale%20joa/2%20-%20capa.jpg",
  },
  {
    title: 'STU',
    capa: 'https://storage.googleapis.com/firma_avi/Home/STU/CAPA.jpg',
    url: 'stu',
  },
  {
    title: 'SPANTA',
    capa: 'https://storage.googleapis.com/firma_avi/soltas/imagens%20soltas%20-%20spanta/2%20-%20capa.jpg',
    url: 'spanta'
  }
]

export const clientes = [
  'https://storage.googleapis.com/firma_avi/CARTELA%20DE%20CLIENTES/farm-rio-logo2.png',
  'https://storage.googleapis.com/firma_avi/CARTELA%20DE%20CLIENTES/animale-logo-preto-01-300x300.png',
  'https://storage.googleapis.com/firma_avi/CARTELA%20DE%20CLIENTES/SKAZI.png',
  'https://storage.googleapis.com/firma_avi/CARTELA%20DE%20CLIENTES/mariafilologo.png',
  'https://storage.googleapis.com/firma_avi/CARTELA%20DE%20CLIENTES/LOGO-FOXTON.png',
  'https://storage.googleapis.com/firma_avi/CARTELA%20DE%20CLIENTES/1200px-Ambev_logo.svg.png',
  'https://storage.googleapis.com/firma_avi/CARTELA%20DE%20CLIENTES/logo-Sereya-Praya-4.png',
  'https://storage.googleapis.com/firma_avi/CARTELA%20DE%20CLIENTES/1200px-Ambev_logo.svgccd.png',
  'https://storage.googleapis.com/firma_avi/CARTELA%20DE%20CLIENTES/bfd534b39dd5af5abb601e49bf06d295.png',
  'https://storage.googleapis.com/firma_avi/CARTELA%20DE%20CLIENTES/SPANTA.png',
  'https://storage.googleapis.com/firma_avi/CARTELA%20DE%20CLIENTES/STU_channel_marca_branco.png',
  'https://storage.googleapis.com/firma_avi/CARTELA%20DE%20CLIENTES/399.png',
  // 'https://storage.goog;leapis.com/firma_avi/CARTELA%20DE%20CLIENTES/farm-rio-logo.png',
];

export const textos = {
  farmrefloresta: {
    title: 'FARM',
    subtitle: 'REFLORESTA',
    subtitle2: 'Campanha oficial de mídias',
  },
  farmaltoverao21: {
    title: 'FARM',
    subtitle: 'Alto verão 21',
    subtitle2: '',
  },
  farmgreenfriday: {
    title: 'FARM',
    subtitle: 'Green Friday',
    subtitle2: 'Live shop',
  },
  liveanimalerj2020: {
    title: 'ANIMALE',
    subtitle: '',
    subtitle2: 'Live',
  },
  animaleexperience: {
    title: 'ANIMALE EXPERIENCE',
    subtitle: '',
    subtitle2: '',
  },
  budmaejoana:{
    title: 'BUDWEISER X BAR MÃE JOANA',
    subtitle: '',
    subtitle2: '',
  },
  budbig399: {
    title: 'BUDWEISER',
    subtitle: 'Bruno Big X BAR 399',
    subtitle2: '',
  },
  'oretorno399': {
    title: '399, O RETORNO!',
    subtitle: '',
    subtitle2: '',
  },
  bailedoencanto:{
    title: 'BAILE DO ENCANTO',
    subtitle: '',
    subtitle2: '',
  },
  spanta: {
    title: 'SPANTA',
    subtitle: '',
    subtitle2: '',
  },
  festadublack: {
    title: 'FESTA DUBLACK',
    subtitle: '',
    subtitle2: '',
  },
  skybrown: {
    title: '',
    subtitle: '',
    subtitle2: '',
  },
  ferrugemanchietx: {
    title: 'FERRUGEM feat ANCHIETX',
    subtitle: 'Djavan',
    subtitle2: '',
  },
  japa: {
    title: 'JAPA',
    subtitle: 'Dejavu',
    subtitle2: '',
  },
  farmriofall20: {
    title: 'FARM RIO',
    subtitle: 'FALL 20',
    subtitle2: '',

  },
  mariafiloverao21: {
    title: 'MARIA FILÓ',
    subtitle: 'Verão 21',
    subtitle2: '',
  },
  animaleorosecretgarden: {
    title: 'Animale Oro',
    subtitle: 'Secret Garden',
    subtitle2: '',

  },
  abrandmemoriasafetivas:{
    title: 'A.BRAND',
    subtitle: 'Memórias afetivas',
    subtitle2: '',
  },
  animaleviajantesdefile: {
    title: 'ANIMALE',
    subtitle: 'Viajantes do futuro',
    subtitle2: 'Desfile',
  },
}

