import React from 'react'
import {Grid, Typography} from '@material-ui/core'
import ReactPlayer from 'react-player'

export default function index({width}) {
  return (
   <Grid
                  style={{
                    // background: `url(${campaign}) center no-repeat`,
                    backgroundSize: 'cover',
                    marginTop: -75,
                    width: '100%',
                  }}
                >
                  <Grid
                    style={{ position: 'relative', top: 150 }}
                  />
                  {/* <Grid style={{ position: 'relative', top: 200 }}>
                    <Typography
                      align="center"
                      style={{
                        fontSize: 44,
                        letterSpacing: '0.1em',
                        color: '#ffffff',
                      }}
                    >
                      SPRING SUMMER 21
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 28,
                        letterSpacing: '0.1em',
                        color: '#ffffff',
                      }}
                      align="center"
                    >
                      FROM RIO WITH LOVE
                    </Typography>
                  </Grid> */}
                  <ReactPlayer playsinline url={"https://storage.googleapis.com/firma_avi/homevideo.mp4"}  loop muted playing width={width ? width : "100%"} height={width ? "90%" : "100%"}/>

                  {/* <ReactPlayer width={width ? width : "100%"} height={width ? "90%" : "100%"}  autoPlay loop muted playsinline>
                    <source
                      src="https://storage.googleapis.com/firma_avi/homevideo.mp4"
                      type="video/mp4"
                    />
                  </ReactPlayer> */}
                  {width && (
                    <Grid style={{position: 'relative', top: -70, height: 60 }}>

                    <Typography style={{position: 'relative', top:window.innerWidth > 960 ? -45 : 10, left:  window.innerWidth > 1920 ? '3%' : '2%' , fontWeight: 'bold', letterSpacing: '0.08em', fontSize:  window.innerWidth > 960 ? 20 : 14}}>A.BRAND</Typography>
                    <Typography style={{position: 'relative', top:window.innerWidth > 960 ? -45 : 10, left:  window.innerWidth > 1920 ? '3%' : '2%'  , fontSize:  window.innerWidth > 960 ? 16 : 12}}>Memórias afetivas</Typography>
                          </Grid>
                  )}
                </Grid>
  )
}
