import { Typography, Grid, Grow, Fade } from '@material-ui/core'
import React from 'react'
import ReactPlayer from 'react-player'



const isMobile = window.innerWidth < 960;

export default function index({ entrete, marginTop, mobileProjeto, index, title, subtitle, height, width, url, marca, subtitle2, onClick, mobile, container, farmav21, farmrefloresta, baile, spanta, home}) {
  // let history = useHistory();
  const wid = window.innerWidth
  console.log(window.innerWidth)
  return (
    !url.includes('.mp4') && !url.includes('.mov') ? (
      <Grow    in={url} timeout={index ? 1000 + index * wid/2 : 2000}>
        <Grid style={{ width: isMobile ? '99%' : width,  margin: home ? '0px 1.5px' : marca ? 5 :  '0.5%', cursor: !marca ? 'pointer' : null, marginTop: spanta ? -60 : marginTop ? 10 :  !marca ? -60 : '0.5%'}} onClick={onClick}>

<img src={url} style={{width: width,  height: 'auto'}} alt='img' />

{(!marca || entrete)  && (
  <Fade in timeout={index ? 1200 + index * wid / 1.5 : 2500}>

  <Grid style={{position: 'relative', top: !isMobile ? -100 : subtitle2 ? -70 : subtitle ? -50 : -40, height: 60 }}>

  <Typography style={{position: 'relative', top: !isMobile ?  !subtitle ? 70 - 25 : !subtitle2 ?70 - 45 : 70 - 60 : 0, left: !mobile ? window.innerWidth > 960 ? '3%' : '2%' : 20, fontWeight: 'bold', letterSpacing: '0.08em', fontSize: !mobile ? 20 : 14}}>{title}</Typography>
<Typography style={{position: 'relative', top: !isMobile? !subtitle ? 70 - 25 : !subtitle2 ?70 - 45 : 70 - 60 : 0, left: !mobile ? window.innerWidth > 960 ? '3%' : '2%' : 20 , fontSize: !mobile ? 16 : 12}}>{subtitle}</Typography>
<Typography style={{position: 'relative', top: !isMobile? !subtitle ? 70 - 25 : !subtitle2 ?70 - 45 : 70 - 60: 0, left: !mobile ? window.innerWidth > 960 ? '3%' : '2%' : 20, fontSize: !mobile ? 16 : 12}}>{subtitle2}</Typography>

</Grid>
</Fade>

)}


</Grid>
      </Grow>


    ) :
    <Grid  style={{margin: '0.5%', marginTop: !marca ? -60 : '0.5%'}}>
      <Grow in timeout={index ? 1200 + index * wid / 1.5 : 2500}>
    <ReactPlayer playsinline url={url}  loop muted playing width={mobileProjeto ? '500px' : baile ? '45%'  : container ? '150px' : mobile ? width : marca || entrete ? width :  '1000px' } height={mobileProjeto ?  '265px' : farmav21 ? '800px' : baile ? '600px' : mobile ? "auto" : marca || entrete ? 'auto' : '700px'} onClick={onClick} style={{  cursor: !marca ? 'pointer' : null}}/>

    </Grow>
    {(!marca || entrete)  && (
  <Fade in timeout={index ? 1200 + index * wid / 1.5 : 2500}>

  <Grid style={{position: 'relative', top: !isMobile ? -100 : subtitle2 ? -70 : subtitle ? -50 : -40, height: 60 }}>

  <Typography style={{position: 'relative', top: !mobile ? height - 90 : !subtitle ? height - 25 : !subtitle2 ?height - 45 : height - 150, left: !mobile ? window.innerWidth > 960 ? '5%' : '2%' : 20, fontWeight: 'bold', letterSpacing: '0.08em', fontSize: !mobile ? 20 : 14}}>{title}</Typography>

</Grid>
</Fade>

)}

    </Grid>

  )
}
