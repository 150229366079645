import React from 'react'
import { Grid, Typography, IconButton} from '@material-ui/core';
import {FaVimeoSquare, FaYoutube, FaLinkedin, FaTwitch, FaWhatsapp, FaInstagram} from 'react-icons/fa';


export default function index({projetos}) {
  return (
    <Grid container justify='space-between' style={{width: '100%', alignItems: 'center',  height: 80, backgroundColor: '#000000'}}>

  <Typography style={{width: '30%',paddingLeft: '2%'}}>contato@firmaavi.com</Typography>

      <Grid style={{width: '55%', paddingRight: '2%'}}>
        <Grid container justify='space-between'>
        <a target="https://instagram.com/firma.avi?igshid=1wxdb6qbdcngc" href='https://instagram.com/firma.avi?igshid=1wxdb6qbdcngc'><IconButton size='small'><FaInstagram size={20}/></IconButton></a>
      <a target="https://vimeo.com/firmaavi" href='https://vimeo.com/firmaavi'><IconButton size='small'><FaVimeoSquare/></IconButton></a>

      <a target="https://www.youtube.com/channel/UCt5GE-WEok4CWXlBfT2TBWQ" href='https://www.youtube.com/channel/UCt5GE-WEok4CWXlBfT2TBWQ'><IconButton size='small'><FaYoutube size={20}/></IconButton></a>

      <a target="https://www.linkedin.com/company/firma-avi/?trk=public_profile_topcard_current_company&originalSubdomain=br" href='https://www.linkedin.com/company/firma-avi/?trk=public_profile_topcard_current_company&originalSubdomain=br'><IconButton size='small'><FaLinkedin size={20}/></IconButton></a>
      <a target="https://www.twitch.tv/firmaavi" href='https://www.twitch.tv/firmaavi'><IconButton size='small'><FaTwitch size={20}/></IconButton></a>

      <a href='https://api.whatsapp.com/send?phone=5521959043345'><IconButton size='small'><FaWhatsapp size={20}/></IconButton></a>
        </Grid>
      </Grid>




    </Grid>
  )
}
