import React from 'react'
import { Grid, Typography, IconButton} from '@material-ui/core';
import {FaVimeoSquare, FaYoutube, FaLinkedin, FaTwitch, FaWhatsapp} from 'react-icons/fa';
import InstagramIcon from '@material-ui/icons/Instagram';


export default function index() {
  return (
    <Grid container justify='space-between' style={{width: '100%', alignItems: 'center', height: 100, marginBottom: -30, marginTop: 50, backgroundColor: '#000000',}}>
      <Grid style={{marginLeft: 50}}>
      <Typography>contato@firmaavi.com</Typography>

      </Grid>

      <Grid style={{marginRight: 30}}>
      <a target="https://instagram.com/firma.avi?igshid=1wxdb6qbdcngc" href='https://instagram.com/firma.avi?igshid=1wxdb6qbdcngc'><IconButton style={{marginLeft: 10}}><InstagramIcon/></IconButton></a>
      <a target="https://vimeo.com/firmaavi" href='https://vimeo.com/firmaavi'><IconButton style={{marginLeft: 10}}><FaVimeoSquare/></IconButton></a>
      <a target="https://www.youtube.com/channel/UCt5GE-WEok4CWXlBfT2TBWQ" href='https://www.youtube.com/channel/UCt5GE-WEok4CWXlBfT2TBWQ'><IconButton style={{marginLeft: 10}}><FaYoutube/></IconButton></a>
      <a target="https://www.linkedin.com/company/firma-avi/?trk=public_profile_topcard_current_company&originalSubdomain=br" href='https://www.linkedin.com/company/firma-avi/?trk=public_profile_topcard_current_company&originalSubdomain=br'><IconButton style={{marginLeft: 10}}><FaLinkedin/></IconButton></a>
      <a target="https://www.twitch.tv/firmaavi" href='https://www.twitch.tv/firmaavi'><IconButton style={{marginLeft: 10}}><FaTwitch/></IconButton></a>

      <a href='https://api.whatsapp.com/send?phone=5521959043345'><IconButton style={{marginLeft: 10}}><FaWhatsapp/></IconButton></a>

      </Grid>



    </Grid>
  )
}
