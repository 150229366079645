import React from 'react';
import {Grid, Typography} from '@material-ui/core'
import firmaIcon from '../../../assets/firmalogo.svg'
import {Link} from 'react-router-dom';

export default function index({refFooter}) {
  return (
    <Grid container justify='space-around' style={{position: 'fixed', alignItems:'center', zIndex: 1}}>
      <Link style={{textDecoration: 'none'}} to=''>
        <img src={firmaIcon} alt='firmaIcon' />
      </Link>
      <Link style={{textDecoration: 'none'}} to='/projetos/moda'><Typography style={{margin: '0px 5px'}}>Projetos</Typography></Link>
      <Link style={{textDecoration: 'none'}} to='/sobre'><Typography style={{margin: '0px 5px'}}>A Firma</Typography></Link>

        <Typography style={{margin: '0px 5px'}} onClick={() => {
            refFooter.current.scrollIntoView({
              behavior: 'smooth',
            });

          }}>Contatos</Typography>




    </Grid>
  )
}
