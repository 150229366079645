import React, {useEffect, useRef, useState, useLayoutEffect} from 'react';

import VideoHome from '../../components/VideoHome';
import LiveGrid from '../../components/LiveGrid';
import Footer from '../../components/Foooter'
import Header from '../../components/HeaderFinale';

import {Grid} from '@material-ui/core'


import {home} from '../../consts/const'

const isMobile = window.innerWidth < 960;

export default function Home(props) {
  const refFooter = useRef();
  const [wid, setWid] = useState(window.innerWidth)

  useLayoutEffect(() => {
    function updateSize() {
      setWid([window.innerWidth]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  return (
   <>
   <Header wid={wid} refFooter={refFooter} />
  <Grid style={{marginTop: 45}}>
  <VideoHome />
  </Grid>


    <Grid container style={{marginBottom: -100}}>

    <Grid container justify='center' style={{marginTop: 60}} >
    {!isMobile ? (
        home.map((item, index) => (
          index !== 4 ? (
            <LiveGrid home index={index}  title={item.title} subtitle={item.subtitle} width={index === 3? wid  * 0.656 : index === 2 ? wid * 0.327 : wid * 0.491 }  url={item.capa} subtitle2={item.subtitle2} onClick={() => props.history.push(`/marcas/${item.url}`)} />

 ) : (
    <Grid >
      <Grid/>
    <LiveGrid index={index} home title={item.title} subtitle={item.subtitle} width={index === 4 ? wid * 0.99 : index === 3? wid  * 0.661 : index === 2 ? wid * 0.325 : wid * 0.495 }  url={item.capa} subtitle2={item.subtitle2} onClick={() => props.history.push(`/marcas/${item.url}`)} />
    <Grid/>
</Grid>
  )
      ) )
    ) : (
      home.map((item, index) => (
        <LiveGrid  title={item.title} subtitle={item.subtitle} width={wid * 0.99}  url={item.capa} mobile subtitle2={item.subtitle2} onClick={() => props.history.push(`/marcas/${item.url}`)} />

      ) )
    )}
    </Grid>
    </Grid>

    <Grid style={{marginTop: isMobile && 30}} ref={refFooter}>
    <Footer />
    </Grid>
   </>
  )
}
