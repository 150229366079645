import React, {useRef, useEffect, useState, useLayoutEffect} from 'react'
import Header from '../../components/HeaderFinale';
import {Grid, Typography, Grow} from '@material-ui/core';
import LiveGrid from '../../components/LiveGrid';
import Footer from '../../components/Foooter';
import { useHistory } from "react-router-dom";
import {projetos} from '../../consts/const';
import ReactPlayer from 'react-player'
import VideoHome from '../../components/VideoHome';


export default function Projetos(props) {
  let history = useHistory();
  const refFooter = useRef();
  const isMobile = window.innerWidth < 960;

  const [wid, setWid] = useState(window.innerWidth)
  useLayoutEffect(() => {
    function updateSize() {
      setWid([window.innerWidth]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    setWid(window.innerWidth)
  }, [wid])

  const [projeto, setProjeto] = useState(null);
  useEffect(() => {
    setProjeto(props.match.params.projeto)
    window.scrollTo(0, 0)
  }, [props.match.params.projeto])

  return (
    <>
    <Header wid={wid} refFooter={refFooter} />
      <Grid container style={{marginTop: -30, background: '#000000', height: window.innerWidth > 2000 ? 300 : 175, alignItems: 'flex-end', marginBottom: projeto === 'entretenimento' && -75, paddingBottom: 20}}>
        {!isMobile ? (
          <Grid container justify={isMobile ? 'space-around' : null} style={{marginTop: 20}}>
                      <Typography style={{ fontSize: wid * 0.01, fontWeight: projeto === 'moda' ? 'bold' : null, marginLeft: isMobile ? 5 : 100, marginRight: isMobile ? 5 : 20,  letterSpacing: isMobile ? 0 : '0.12em', cursor: 'pointer'}} onClick={() => {window.moveTo(0,0); history.push('/projetos/moda')}}>moda</Typography>

          <Typography style={{fontSize: wid * 0.01, fontWeight: projeto === 'streaming' ? 'bold' : null, margin: isMobile ? '0px 5px' : '0px 20px',  letterSpacing: isMobile ? 0 : '0.12em', cursor: 'pointer'}} onClick={() => {window.moveTo(0,0); history.push('/projetos/streaming')}}>streaming</Typography>
          <Typography style={{fontSize: wid * 0.01, fontWeight: projeto === 'branded' ? 'bold' : null, margin: isMobile ? '0px 5px' : '0px 20px',  letterSpacing: isMobile ? 0 : '0.12em', cursor: 'pointer'}} onClick={() =>{window.moveTo(0,0); history.push('/projetos/branded')}}>branded content</Typography>
          <Typography style={{fontSize: wid * 0.01, fontWeight: projeto === 'entretenimento' ? 'bold' : null, margin: isMobile ? '0px 5px' : '0px 20px',  letterSpacing: isMobile ? 0 : '0.12em', cursor: 'pointer'}} onClick={() => {window.moveTo(0,0); history.push('/projetos/entretenimento')}}>entretenimento</Typography>
          <Typography style={{fontSize: wid * 0.01, fontWeight: projeto === 'outros' ? 'bold' : null, margin: isMobile ? '0px 5px' : '0px 20px',  letterSpacing: isMobile ? 0 : '0.12em', cursor: 'pointer'}} onClick={() => {window.moveTo(0,0); history.push('/projetos/outros')}}>outras entregas</Typography>
        </Grid>
        ) : (
          <Grid justify='center' container style={{marginTop: 100}}>
                      <Typography style={{fontWeight: projeto === 'moda' ? 'bold' : null, marginRight: 5, fontSize: 15}} onClick={() => {window.moveTo(0,0); history.push('/projetos/moda')}}> moda | </Typography>

            <Typography style={{fontWeight: projeto === 'streaming' ? 'bold' : null, marginRight: 5, fontSize: 15}} onClick={() => {window.moveTo(0,0); history.push('/projetos/streaming')}}>streaming | </Typography>
          <Typography style={{fontWeight: projeto === 'branded' ? 'bold' : null, marginRight: 5, fontSize: 15}} onClick={() => {window.moveTo(0,0); history.push('/projetos/branded')}}> branded | </Typography>
          <Typography style={{fontWeight: projeto === 'entretenimento' ? 'bold' : null, marginRight: 5, fontSize: 15}} onClick={() => {window.moveTo(0,0); history.push('/projetos/entretenimento')}}> entretenimento |</Typography>

          <Typography style={{fontWeight: projeto === 'outros' ? 'bold' : null, fontSize: 15}} onClick={() => {window.moveTo(0,0); history.push('/projetos/outros')}}>outros</Typography>

          </Grid>
        )}
      </Grid >
      <Grid style={{position: 'relative', top: !isMobile ? wid * 0.05 : 65, marginBottom: window.innerWidth > 2000 ? 200 :   !isMobile  && 100 }}>

        {window.innerWidth > 960 ?
        (
          <Grid container justify='center'>
            <Grid container justify='center'>
            {projeto === 'moda' && projetos.moda.map((marca, index) => (
           <LiveGrid index={index} title={marca.title} subtitle={marca.subtitle} width={index === 5 ? wid * 0.91 : index > 1 && index < 5 ? wid * 0.297 : wid * 0.45}  url={marca.capa} subtitle2={marca.subtitle2} onClick={() => props.history.push({pathname: `/marcas/${marca.url}`, state: { title: marca.title, subtitle: marca?.subtitle, subtitle2: marca?.subtitle2}})}/>
     ))}

            </Grid>
        {projeto === 'branded' && projetos.branded.map((marca, index) => (
           <LiveGrid index={index} title={marca.title} subtitle={marca.subtitle} width={index !== 0 ? wid * 0.445 : wid * 0.9} url={marca.capa} subtitle2={marca.subtitle2} onClick={() => props.history.push({pathname: `/marcas/${marca.url}`, state: { title: marca.title, subtitle: marca?.subtitle, subtitle2: marca?.subtitle2}})}/>
     ))}
        {projeto === 'streaming' && projetos.streaming.map((marca, index) => (
           <LiveGrid index={index}  title={marca.title} subtitle={marca.subtitle} width={index === 0  ? wid * 0.63 : index === 1 ?  wid * 0.261 : wid * 0.9} url={marca.capa} subtitle2={marca.subtitle2} onClick={() => props.history.push({pathname: `/marcas/${marca.url}`, state: { title: marca.title, subtitle: marca?.subtitle, subtitle2: marca?.subtitle2}})} />
     ))}


        {projeto === 'entretenimento' && projetos.entretenimento.map((marca, index) => (
                       <LiveGrid  marca entrete  index={index}  spanta={index === 0 || index === 2}  title={marca.title} subtitle={marca.subtitle} width={index === 0 || index === 1 ? wid * 0.445 : wid * 0.9}  url={marca.capa} subtitle2={marca.subtitle2} onClick={() => props.history.push({pathname: `/marcas/${marca.url}`, state: { title: marca.title, subtitle: marca?.subtitle, subtitle2: marca?.subtitle2}})} />

     ))}

        {projeto === 'outros' &&
        (
          <>
          <Grid container justify='center' style={{marginTop: -55}} >
         <Grow in timeout={4000}>
         <ReactPlayer  url="https://vimeo.com/470607046"  width={wid * 0.905} height={wid * 0.505} controls />
         </Grow>

          </Grid>
          {projetos.outros.map((marca,index) => (
             <LiveGrid marginTop  index={index} title={marca.title} subtitle={marca.subtitle} width={index === 0 || index === 1 ? wid * 0.445 : wid * 0.9}  url={marca.capa} subtitle2={marca.subtitle2} onClick={() => props.history.push({pathname: `/marcas/${marca.url}`, state: { title: marca.title, subtitle: marca?.subtitle, subtitle2: marca?.subtitle2}})} />
        ))}
          </>
        )
        }
          </Grid>
        )
        : (
          <Grid container justify='center'>
          {projeto === 'moda' && projetos.moda.map((marca, index) => (
           <LiveGrid  title={marca.title} subtitle={marca.subtitle} width={wid * 0.99}  url={marca.capa} mobile subtitle2={marca.subtitle2} onClick={() => props.history.push({pathname: `/marcas/${marca.url}`, state: { title: marca.title, subtitle: marca?.subtitle, subtitle2: marca?.subtitle2}})}/>
     ))}
     {projeto === 'moda' && (
              <Grid style={{marginTop: 15}}>
              <VideoHome width={wid * 0.99} />
              </Grid>
           )}

{projeto === 'branded' && projetos.branded.map((marca, index) => (
           <LiveGrid  title={marca.title} subtitle={marca.subtitle} width={wid * 0.99}  url={marca.capa} mobile subtitle2={marca.subtitle2} onClick={() => props.history.push({pathname: `/marcas/${marca.url}`, state: { title: marca.title, subtitle: marca?.subtitle, subtitle2: marca?.subtitle2}})}/>
     ))}

{projeto === 'streaming' && projetos.streaming.map((marca, index) => (
           <LiveGrid  title={marca.title} subtitle={marca.subtitle} width={wid * 0.99}  url={marca.capa} mobile subtitle2={marca.subtitle2} onClick={() => props.history.push({pathname: `/marcas/${marca.url}`, state: { title: marca.title, subtitle: marca?.subtitle, subtitle2: marca?.subtitle2}})}/>
     ))}

<Grid style={{marginTop: 60}}>
{projeto === 'entretenimento' && projetos.entretenimento.map((marca, index) => (
        <Grid>
                     <LiveGrid  spanta={index === 1} title={marca.title} subtitle={marca.subtitle} width={wid * 0.99}  url={marca.capa} mobile subtitle2={marca.subtitle2} onClick={() => props.history.push({pathname: `/marcas/${marca.url}`, state: { title: marca.title, subtitle: marca?.subtitle, subtitle2: marca?.subtitle2}})}/>

        </Grid>
     ))}
</Grid>

{projeto === 'outros' &&
        (
          <Grid >
            <>
          <Grid container justify='center' style={{position: 'relative', top: -70}}>
          <ReactPlayer url="https://vimeo.com/470607046"  width={wid * 0.99} height="230px"  controls />

          </Grid>
          {projetos.outros.map((marca,index) => (
             <LiveGrid  title={marca.title} subtitle={marca.subtitle} width={wid * 0.99}  url={marca.capa} subtitle2={marca.subtitle2} onClick={() => props.history.push({pathname: `/marcas/${marca.url}`, state: { title: marca.title, subtitle: marca?.subtitle, subtitle2: marca?.subtitle2}})} />
        ))}
          </>
          </Grid>
        )
        }

          </Grid>
        )}


      </Grid>
      <Grid ref={refFooter}>
    <Footer />
    </Grid>
    </>
  )
}
