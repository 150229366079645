import React from 'react'
import { Switch, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Projetos from '../pages/Projetos';
import Sobre from '../pages/Sobre'
import Marca from '../pages/Marca';

export default function index() {
  return (
   <Switch>
     <Route path='/' exact component={Home}/>
     <Route path='/projetos/:projeto' component={Projetos}/>
     <Route path='/sobre' component={Sobre}/>
     <Route path='/marcas/:marca' component={Marca} />
   </Switch>
  )
}

