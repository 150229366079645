import React, {useRef, useEffect, useState, useLayoutEffect} from 'react'
import {Grid, Typography, Grow} from '@material-ui/core';
import Header from '../../components/HeaderFinale';
import Footer from '../../components/Foooter';
import LiveGrid from '../../components/LiveGrid';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';


import {marcas, textos} from '../../consts/const';
import ReactPlayer from 'react-player';

export default function Marca(props) {

  const refFooter = useRef();
  const [playing, setPlaying] = useState(false);
  const [marca, setmarca] = useState(null);
  const [title, setTitle] = useState('');
  const [subTitle, setSubtitle] = useState('');
  const [sub2, setsub2] = useState('');

  const [wid, setWid] = useState(window.innerWidth)

  useLayoutEffect(() => {
    function updateSize() {
      setWid([window.innerWidth]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
    setmarca(props.match.params.marca)
    if(props.match.params.marca === 'farmrefloresta'){
      setTitle(textos.farmrefloresta.title);
      setSubtitle(textos.farmrefloresta.subtitle);
      setsub2(textos.farmrefloresta.subtitle2);
    }
    if(props.match.params.marca === 'farmaltoverao21'){
      setTitle(textos.farmaltoverao21.title);
      setSubtitle(textos.farmaltoverao21.subtitle);
      setsub2(textos.farmaltoverao21.subtitle2);
    }
    if(props.match.params.marca === 'farmriofall20'){
      setTitle(textos.farmriofall20.title);
      setSubtitle(textos.farmriofall20.subtitle);
      setsub2(textos.farmriofall20.subtitle2);
    }
    if(props.match.params.marca === 'mariafiloverao21'){
      setTitle(textos.mariafiloverao21.title);
      setSubtitle(textos.mariafiloverao21.subtitle);
      setsub2(textos.mariafiloverao21.subtitle2);
    }
    if(props.match.params.marca === 'animaleorosecretgarden'){
      setTitle(textos.animaleorosecretgarden.title);
      setSubtitle(textos.animaleorosecretgarden.subtitle);
      setsub2(textos.animaleorosecretgarden.subtitle2);
    }
    if(props.match.params.marca === 'abrandmemoriasafetivas'){
      setTitle(textos.abrandmemoriasafetivas.title);
      setSubtitle(textos.abrandmemoriasafetivas.subtitle);
      setsub2(textos.abrandmemoriasafetivas.subtitle2);
    }
    if(props.match.params.marca === 'animaleviajantesdefile'){
      setTitle(textos.animaleviajantesdefile.title);
      setSubtitle(textos.animaleviajantesdefile.subtitle);
      setsub2(textos.animaleviajantesdefile.subtitle2);
    }
    if(props.match.params.marca === 'farmgreenfriday'){
      setTitle(textos.farmgreenfriday.title);
      setSubtitle(textos.farmgreenfriday.subtitle);
      setsub2(textos.farmgreenfriday.subtitle2);
    }
    if(props.match.params.marca === 'liveanimalerj2020'){
      setTitle(textos.liveanimalerj2020.title);
      setSubtitle(textos.liveanimalerj2020.subtitle);
      setsub2(textos.liveanimalerj2020.subtitle2);
    }
    if(props.match.params.marca === 'animaleexperience'){
      setTitle(textos.animaleexperience.title);
      setSubtitle(textos.animaleexperience.subtitle);
      setsub2(textos.animaleexperience.subtitle2);
    }
    if(props.match.params.marca === 'budmaejoana'){
      setTitle(textos.budmaejoana.title);
      setSubtitle(textos.budmaejoana.subtitle);
      setsub2(textos.budmaejoana.subtitle2);
    }
    if(props.match.params.marca === 'budbig399'){
      setTitle(textos.budbig399.title);
      setSubtitle(textos.budbig399.subtitle);
      setsub2(textos.budbig399.subtitle2);
    }
    if(props.match.params.marca === 'oretorno399'){
      setTitle(textos.oretorno399.title);
      setSubtitle(textos.oretorno399.subtitle);
      setsub2(textos.oretorno399.subtitle2);
    }
    if(props.match.params.marca === 'bailedoencanto'){
      setTitle(textos.bailedoencanto.title);
      setSubtitle(textos.bailedoencanto.subtitle);
      setsub2(textos.bailedoencanto.subtitle2);
    }
    if(props.match.params.marca === 'festadublack'){
      setTitle(textos.festadublack.title);
      setSubtitle(textos.festadublack.subtitle);
      setsub2(textos.festadublack.subtitle2);
    }
    if(props.match.params.marca === 'spanta'){
      setTitle(textos.spanta.title);
      setSubtitle(textos.spanta.subtitle);
      setsub2(textos.spanta.subtitle2);
    }
    if(props.match.params.marca === 'ferrugemanchietx'){
      setTitle(textos.ferrugemanchietx.title);
      setSubtitle(textos.ferrugemanchietx.subtitle);
      setsub2(textos.ferrugemanchietx.subtitle2);
    }
    if(props.match.params.marca === 'japa'){
      setTitle(textos.japa.title);
      setSubtitle(textos.japa.subtitle);
      setsub2(textos.japa.subtitle2);
    }

  }, [props.match.params.marca])
  return (
    <>
    <Header wid={wid} refFooter={refFooter}/>
      <Grid container justify='center' style={{ alignItems: 'center', paddingBottom: wid * 0.05, paddingTop: window.innerWidth > 960 ? wid * 0.1 : wid * 0.2}}>
       <Grow in timeout={1000}>
          <Grid  >
        <Typography align='center' style={{fontSize: window.innerWidth > 2000 ? '120px' : window.innerWidth > 960 ? '42px' : '30px'}} >{title}</Typography>
        <Typography align='center' style={{fontSize: window.innerWidth > 2000 ? '55px' : window.innerWidth > 960 ? '18px' : '12px'}}  >{subTitle}</Typography>
        <Typography align='center' style={{fontSize: window.innerWidth > 2000 ? '32px' : window.innerWidth > 960 ? '14px' : '10px'}}  >{sub2}</Typography>
        </Grid>
       </Grow>
      </Grid>

      {window.innerWidth > 960 || marca === 'farmrefloresta' ? (
        <Grid container justify='center' >

{marca === 'stu' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/480972882"  width="2000px" height={wid * 0.51} style={{margin: '0.5%'}}  controls />
          </Grow>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/480972509"  width="2000px" height={wid * 0.51} style={{margin: '0.5%'}}  controls />
          </Grow>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/480972043"  width="2000px" height={wid * 0.51} style={{margin: '0.5%'}}  controls />
          </Grow>
          <Grow in timeout={3000} >
          <ReactPlayer url="https://vimeo.com/480972332"  width="2000px" height={wid * 0.51} style={{margin: '0.5%'}}  controls />
</Grow>

          {/* {marca === 'stu' && marcas.stu.map((photo,index) => (
             <LiveGrid  width={'100%'} height={500} url={photo} marca  />
        ))} */}
          </>
        )
        }

{marca === 'farmaltoverao21' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/514522126"  width={wid * 0.9} height={wid * 0.51} style={{margin: '0.5%'}} controls />
          </Grow>
          {marcas.farmaltoverao21.map((photo,index) => (
            <LiveGrid index={index} width={index === 0 ? wid * 0.472 : index === 1 ? wid * 0.42 : index === 10 ? wid * 0.893 : index === 2 ? wid * 0.61155 : index === 3 ? wid * 0.2763 : index === 4 ? wid * 0.312 : index === 5 ? wid * 0.579 : index === 6 ? wid * 0.578 : index === 7 ? wid *0.3096 :   index === 8 ? wid * 0.318 : index === 9 ? wid *0.565 : wid * 0.783} url={photo} marca />
          ))}
          </>
        )
        }

{marca === 'farmgreenfriday' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/499220333"  width={wid * 0.9} height={wid * 0.51} style={{margin: '0.5%'}} controls />
          </Grow>
          <Grid container justify='center' >
          {marcas.farmgreenfriday.map((photo,index) => (
            <LiveGrid index={index} height={1077}  width={index === 0 || index === 3 ? wid * 0.9 : wid * 0.4472} url={photo} marca farmav21={index === 4 || index === 6 ? '19%' : '27%'}/>
          ))}
          </Grid>
          </>
        )
        }

          {/* {marca === 'farmgreenfriday' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/499220333"  width={wid * 0.9} height={wid * 0.51} style={{margin: '0.5%'}} controls />
          </Grow>
          <Grid container justify='center'>
          {marcas.farmgreenfriday.map((photo,index) => (
            <LiveGrid marginTop  width={index === 0 || index === 3 ? wid * 0.9 : wid * 0.45} marca url={photo} />
          ))}
          </Grid>
          </>
        )
        } */}
         {marca === 'farmrefloresta' &&
        (
          <>
          <Grow  in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/515405488"  width={wid * 0.9} height={wid * 0.51} style={{margin: '0.5%'}}controls />

</Grow>
         <Grid container justify='center' >
         {marcas.farmrefloresta.map((photo,index) => (
            <LiveGrid  index={index} marca width={index === 0  ? wid * 0.9 : wid * 0.4475} url={photo} />
          ))}
                    </Grid>

          </>
        )
        }
        {marca === 'liveanimalerj2020' &&
        (
          <>
          <Grid container justify='center'>
          <Grow  in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/502765315"  width={wid * 0.9} height={wid * 0.51} style={{margin: '0.5%'}}ontrols />

</Grow>

          </Grid>
          {marca === 'liveanimalerj2020' && marcas.liveanimalerj2020.map((photo,index) => (
             <LiveGrid index={index} width={index === 0 ? wid * 0.3344 : index === 1 ? wid * 0.56 : index === 4 ? wid * 0.3719 : index === 3 ? wid * 0.5225 :  wid * 0.9} url={photo} marca/>
        ))}
          </>
        )
        }

        {marca === 'animaleexperience' &&
        (
          <>
           <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/504997176/89adea3902"  width={wid * 0.9} height={wid * 0.51} style={{margin: '0.5%'}}controls
 />
 </Grow>
          {marca === 'animaleexperience' && marcas.animaleexperience.map((photo,index) => (
             <LiveGrid index={index} width={wid * 0.9} marca   url={photo} />
        ))}
          </>
        )
        }


        {marca === 'budmaejoana' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/470611662/e44c087dc7"  width="2000px" height={wid * 0.51} style={{margin: '0.5%'}} controls />
          </Grow>
          {/* {marca === 'budmaejoana' && marcas.budmaejoana.map((photo,index) => (
             <LiveGrid  width='100%' height={800} url={photo} />
        ))} */}
          </>
        )
        }

        {marca === 'budbig399' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/435737466"  width="2000px" height={wid * 0.51} style={{margin: '0.5%'}} controls />
          </Grow>
          {/* {marca === 'budmaejoana' && marcas.budmaejoana.map((photo,index) => (
             <LiveGrid  width='100%' height={800} url={photo} />
        ))} */}
          </>
        )
        }

        {marca === 'oretorno399' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/435741637"  width="2000px" height={wid * 0.51} style={{margin: '0.5%'}} controls />
          </Grow>
          {/* {marca === 'budmaejoana' && marcas.budmaejoana.map((photo,index) => (
             <LiveGrid  width='100%' height={800} url={photo} />
        ))} */}
          </>
        )
        }

         {marca === 'bailedoencanto' && marcas.bailedoencanto.map((photo,index) => (
           <Grid  style={{margin: '0.5%'}}>
             <LiveGrid  width={wid * 0.45}  url={photo} marca />
          </Grid>
        ))}

        {marca === 'spanta' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/470592277"  width={wid * 0.9} height={wid * 0.51} style={{margin: '0.5%'}} controls />
          </Grow>
          {marca === 'spanta' && marcas.spanta.map((photo,index) => (
             <LiveGrid index={index} width={ index === 1 ? wid * 0.322 : index === 2 ? wid * 0.573 : index === 4 || index === 5 ? wid * 0.4469 : wid * 0.90}  url={photo} marca />
        ))}
          </>
        )
        }

         {marca === 'festadublack' && marcas.festadublack.map((photo,index) => (
             <Grid  style={{margin: '0.5%'}}>
             <LiveGrid  width={wid * 0.45}  url={photo} marca />
          </Grid>
        ))}

        {marca === 'skybrown' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/470607046"  width="2000px" height={wid * 0.51} style={{margin: '0.5%'}} controls />
          </Grow>
          {/* {marca === 'budmaejoana' && marcas.budmaejoana.map((photo,index) => (
             <LiveGrid  width='100%' height={800} url={photo} />
        ))} */}
          </>
        )
        }

        {marca === 'ferrugemanchietx' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://www.youtube.com/watch?v=RZbcLkAExiQ"  width={wid * 0.901} height={wid * 0.51} style={{margin: '0.5%'}} controls />
          </Grow>
          {marca === 'ferrugemanchietx' && marcas.ferrugemanchietx.map((photo,index) => (
             <LiveGrid index={index} marginTop width={index === 0 ? wid * 0.62 : index === 1 ? wid * 0.275 : wid * 0.9}  url={photo} marca />
        ))}
          </>
        )
        }

        {marca === 'japa' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://www.youtube.com/watch?v=jX-ooBxY9Mw"  width={wid * 0.901} height={wid * 0.51} style={{margin: '0.5%'}} controls />
          </Grow>
          {marca === 'japa' && marcas.japa.map((photo,index) => (
             <LiveGrid index={index} marginTop  width={index !== 2 ? wid *0.4472 : wid * 0.9} url={photo} marca  />
        ))}
          </>
        )
        }


        {marca === 'farmriofall20' &&
        (
          <>

          <Grid container justify='center'>
          {marca === 'farmriofall20' && marcas.farmriofall20.map((photo,index) => (
            <LiveGrid index={index} width={index === 0 ? wid * 0.36 : index === 1 ? wid * 0.528 : index === 2 || index === 5 ? wid * 0.9 :  index === 7 ? wid * 0.465 : index === 6 ? wid * 0.4285 : wid * 0.445}  url={photo} marca  />

        ))}
          </Grid>
          </>
        )
        }


        {marca === 'mariafiloverao21' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/456247074"  width={wid * 0.9} height={wid * 0.504} style={{margin: '0.5%'}} controls />
          </Grow>
          {marca === 'mariafiloverao21' && marcas.mariafiloverao21.map((photo,index) => (
             <LiveGrid marginTop index={index} width={index === 2 ? wid * 0.90 : wid * 0.447}  url={photo} marca  />
        ))}
          </>
        )
        }



        {marca === 'animaleorosecretgarden' &&
        (
          <>
          <Grid justify='center' container>
          <Grow in timeout={3000} >
          <ReactPlayer url="https://vimeo.com/480978381"  width={wid * 0.9} height={wid * 0.51} style={{margin: '0.5%'}} controls />
</Grow>

          </Grid>
          {marca === 'animaleorosecretgarden' && marcas.animaleorosecretgarden.map((photo,index) => (
             <LiveGrid index={index}  width={index === 1 || index === 2 ? wid * 0.4472 : wid * 0.9}  url={photo} marca  />
        ))}
          </>
        )
        }


        {marca === 'abrandmemoriasafetivas' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://storage.googleapis.com/firma_avi/homevideo.mp4" light playing playIcon={
          <Grid
                        container
                        justify="center"
                        style={{
                          background: `url(https://storage.googleapis.com/firma_avi/abrand_bg.PNG) no-repeat`,
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                          height: 770,
                          width: '100%',
                        }}
                      >
                         <Grid
                          container
                          justify="center"
                          alignItems="center"
                          onClick={() => setPlaying(!playing)}
                        >
                          <PlayCircleOutlineIcon style={{fontSize: 36}} />
                        </Grid>
                      </Grid>
                    }  width='95%' height="100%" controls />
                  </Grow>
          {/* {marca === 'abrandmemoriasafetivas' && marcas.abrandmemoriasafetivas.map((photo,index) => (
             <LiveGrid  width='47%' height={800} url={photo} marca  /> */}
          </>
        )
        }

        {marca === 'animaleviajantesdefile' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/500215135"  width={wid * 0.9} height={wid * 0.51} style={{margin: '0.5%'}} controls />
          </Grow>
          {marca === 'animaleviajantesdefile' && marcas.animaleviajantesdefile.map((photo,index) => (
             <LiveGrid index={index}  width={index === 1 || index === 2 ? wid * 0.4472 : wid * 0.9}  url={photo} marca  />
        ))}
          </>
        )
        }


        </Grid>
      ) : (
        <Grid container justify='center'>

          {marca === 'farmaltoverao21' &&
        (
          <Grid container justify='center'>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/514522126"  width={wid * 0.99} height="230px" controls />
         </Grow>
           <Grid style={{marginTop: 60}}>
         {marcas.farmaltoverao21.map((photo,index) => (
                     <LiveGrid index={index} width={wid * 0.99} url={photo} marca  />

          ))}
          </Grid>
          </Grid>
        )
        }

{marca === 'farmrefloresta' &&
        (
          <Grid container justify='center'>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/514522126"  width={wid * 0.99} height="230px" controls />
          </Grow>
          <Grid >
          {marcas.farmrefloresta.map((photo,index) => (
            <Grid style={{ margin: '5px 0px'}}>
              <LiveGrid index={index} width={wid * 0.99} url={photo}   />
            </Grid>
          ))}

          </Grid>
          </Grid>
        )
        }


        {marca === 'farmgreenfriday' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/499220333"  width="99%" height="230px" controls />
         </Grow>
           <Grid style={{marginTop: 5}}>
         {marcas.farmgreenfriday.map((photo,index) => (
            <LiveGrid index={index} width={wid * 0.99} url={photo} marca/>
          ))}
         </Grid>
          </>
        )
        }
        {marca === 'liveanimalerj2020' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/502765315"  width="99%" height="230px" controls />
         </Grow>
           <Grid style={{marginTop: 5}}>
         {marca === 'liveanimalerj2020' && marcas.liveanimalerj2020.map((photo,index) => (
             <LiveGrid index={index} width={wid * 0.99} url={photo} marca/>
        ))}
         </Grid>
          </>
        )
        }

        {marca === 'animaleexperience' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/504997176/89adea3902" width="99%" height="230px" controls />
          </Grow>
          <Grid style={{marginTop: 5}}>
          {marca === 'animaleexperience' && marcas.animaleexperience.map((photo,index) => (
             <LiveGrid index={index}  width={wid * 0.99} url={photo} />
        ))}
          </Grid>
          </>
        )
        }


        {marca === 'budmaejoana' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/470611662/e44c087dc7"  width="99%" height="230px" controls />
          </Grow>
          {/* {marca === 'budmaejoana' && marcas.budmaejoana.map((photo,index) => (
             <LiveGrid  width='100%' height={800} url={photo} />
        ))} */}
          </>
        )
        }

        {marca === 'budbig399' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/435737466"  width="99%" height="230px" controls />
          </Grow>
          {/* {marca === 'budmaejoana' && marcas.budmaejoana.map((photo,index) => (
             <LiveGrid  width='100%' height={800} url={photo} />
        ))} */}
          </>
        )
        }

        {marca === 'oretorno399' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/435741637"  width="99%" height="230px" controls />
          </Grow>
          {/* {marca === 'budmaejoana' && marcas.budmaejoana.map((photo,index) => (
             <LiveGrid  width='100%' height={800} url={photo} />
        ))} */}
          </>
        )
        }


         {marca === 'bailedoencanto' && marcas.bailedoencanto.map((photo,index) => (
             <LiveGrid index={index} width={wid * 0.99} url={photo} marca mobile />
        ))}

        {marca === 'spanta' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/470592277"  width="99%" height="230px" controls />
          </Grow>
          <Grid style={{marginTop: 5}}>
          {marca === 'spanta' && marcas.spanta.map((photo,index) => (
             <LiveGrid index={index}  width={wid * 0.99} url={photo} marca />
        ))}
          </Grid>
          </>
        )
        }

         {marca === 'festadublack' && marcas.festadublack.map((photo,index) => (
             <LiveGrid index={index} width={wid * 0.99} url={photo} marca mobile/>
        ))}

        {marca === 'skybrown' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/470607046"  width="99%" height="230px" controls />
          </Grow>
          {/* {marca === 'budmaejoana' && marcas.budmaejoana.map((photo,index) => (
             <LiveGrid  width='100%' height={800} url={photo} />
        ))} */}
          </>
        )
        }

        {marca === 'ferrugemanchietx' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://www.youtube.com/watch?v=RZbcLkAExiQ" width="99%" height="230px"controls />
          </Grow>
          <Grid style={{marginTop: 5}}>
          {marca === 'ferrugemanchietx' && marcas.ferrugemanchietx.map((photo,index) => (
             <LiveGrid index={index} width={wid * 0.99} url={photo} marca />
        ))}
          </Grid>
          </>
        )
        }

        {marca === 'japa' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://www.youtube.com/watch?v=jX-ooBxY9Mw"  width="99%" height="230px" controls />
         </Grow>
           <Grid style={{marginTop: 5}}>
         {marca === 'japa' && marcas.japa.map((photo,index) => (
             <LiveGrid index={index} width={wid * 0.99} url={photo} marca mobile />
        ))}
         </Grid>
          </>
        )
        }


        {marca === 'farmriofall20' &&
        (
          <>

          <Grid style={{marginTop: 5}}>
          {marca === 'farmriofall20' && marcas.farmriofall20.map((photo,index) => (
              <LiveGrid marca  width={wid * 0.99} url={photo}   />
        ))}
          </Grid>
          </>
        )
        }


        {marca === 'mariafiloverao21' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/456247074"  width="99%" height="230px" controls />
          </Grow>
          <Grid style={{marginTop: 5}}>
          {marca === 'mariafiloverao21' && marcas.mariafiloverao21.map((photo,index) => (
             <LiveGrid index={index} width={wid * 0.99} url={photo} marca  />
        ))}
          </Grid>
          </>
        )
        }



        {marca === 'animaleorosecretgarden' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/480978381"  width="99%" height="230px" controls />
         </Grow>
           <Grid style={{marginTop: 5}}>
         {marca === 'animaleorosecretgarden' && marcas.animaleorosecretgarden.map((photo,index) => (
             <LiveGrid index={index} width={wid * 0.99} url={photo} marca  />
        ))}
         </Grid>
         </>

        )
        }


        {marca === 'abrandmemoriasafetivas' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://storage.googleapis.com/firma_avi/homevideo.mp4"  width="99%" height="230px" controls />
          </Grow>
          {/* {marca === 'abrandmemoriasafetivas' && marcas.abrandmemoriasafetivas.map((photo,index) => (
             <LiveGrid  width='47%' height={800} url={photo} marca  /> */}
          </>
        )
        }

        {marca === 'animaleviajantesdefile' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/500215135"  width="99%" height="230px" controls />
          </Grow>
          <Grid style={{marginTop: 5}}>
          {marca === 'animaleviajantesdefile' && marcas.animaleviajantesdefile.map((photo,index) => (
             <LiveGrid index={index} width={wid * 0.99} url={photo} marca  />
        ))}
          </Grid>
          </>
        )
        }

{marca === 'stu' &&
        (
          <>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/480972882"  width="99%" height="230px" controls style={{marginBottom: 20}} />
          </Grow>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/480972509"  width="99%" height="230px" controls style={{marginBottom: 20}} />
          </Grow>
          <Grow in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/480972043"  width="99%" height="230px" controls style={{marginBottom: 20}} />
          </Grow>
          <Grow  in timeout={3000}>
          <ReactPlayer url="https://vimeo.com/480972332"  width="99%" height="230px" controls style={{marginBottom: 20}} />
</Grow>

          {/* {marca === 'stu' && marcas.stu.map((photo,index) => (
             <LiveGrid  width={'100%'} height={500} url={photo} marca  />
        ))} */}
          </>
        )
        }


        </Grid>
      )}






    <Grid ref={refFooter}>
    <Footer />
    </Grid>
    </>
  )
}
