import React, {useRef, useEffect, useLayoutEffect, useState} from 'react';
import Header from '../../components/HeaderFinale';
import Footer from '../../components/Foooter';
import {Typography, Grid, Grow} from '@material-ui/core';
import {clientes} from '../../consts/const'



export default function Sobre() {
  useEffect(() => {
    window.scrollTo(0, 0)
  })
  const refFooter = useRef();
  const [wid, setWid] = useState(window.innerWidth)
  useLayoutEffect(() => {
    function updateSize() {
      setWid([window.innerWidth]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    setWid(window.innerWidth)
  }, [wid])
  const equipe = [
    {
      job: 'Sócio/Diretor de projetos',
      name: 'Yan Felipe'
    },

    {
      job: 'Sócio/Diretor de audiovisual',
      name: 'Rodrigo Siqueira'
    },
    {
      job: 'Produtor Executivo',
      name: 'Gabriel Von'
    },
    {
      job: 'Fotógrafo/Gifmaker',
      name: 'Matheus Gomes'
    },
    {
      job: 'Designer',
      name: 'Daniel Henrick'
    },
    {
      job: 'Produtor',
      name: 'Lucas Passos'
    },

    {
      job: 'Designer/editor',
      name: 'Matheus Saldanha'
    },
    {
      job: 'Produtor',
      name: 'Anderson Batista',
    },
    {
      job: 'Filmmaker/Editor',
      name: 'Bernardo Vasconcelos'
    },
    {
      name: 'Bárbara Liberato',
      job: "Filmmaker",
    },
    {
      name: 'Guilherme Gomes',
      job: 'Assistente de produção'
    },
    {
      name: 'Pedro Gélio',
      job: 'Fotógrafo',
    },
    {
      name: 'Clara Ribeiro',
      job: 'Mídias sociais'
    }
  ]
  return (
    <div>
    <Header refFooter={refFooter} />
      <Grid container justify='center' alignItems='center' style={{padding: '100px 0px'  }}>
        <Grow in timeout={2000}>
        <Typography style={{maxWidth: wid > 960 ? 1137 : '80%'}} align='center' variant='h6'>Surgimos da vontade de trabalhar com o que amamos e hoje, baseados no Rio de Janeiro e de malas sempre prontas pra acompanhar nossos parceiros onde precisarem, atuamos em diversas areas do mercado audiovisual e conteúdo, desde o desenvolvimento da sua ideia, até torna-la concreta. Fotografamos, filmamos, transmitimos e produzimos arte, somos um time de artistas visuais apaixonados pelo fazem, dispostos a colaborar no seu projeto.</Typography>
        </Grow>
      </Grid>
      <Grid container justify='center' style={{alignItems: 'center', padding: '20px 0px 100px'}}>
        {clientes.map((cliente, index) => (
                               <Grow in timeout={1000 + (index * 250)}>

          <Grid item  xs={6} md={4}>

           <Grid container justify='space-evenly' >
           <img style={{width: wid > 960 ? '33%' : '50%', height: wid > 960 ? '33%' : '50%'}}src={cliente} alt='brands' />
            </Grid>

          </Grid>
          </Grow>

        ))}
      </Grid>
      <Grid   container  align='center' justify='space-evenly'>
        {equipe.map((pessoa, index) => (
                     <Grow in timeout={1000 + (index * 250)}>

          <Grid  style={{margin: window.innerWidth > 960 ? '50px 0px' : '40px 20px' }} item md={4}  key={index}>
            <Typography style={{fontWeight: 'bold', fontSize: window.innerWidth < 960 && 15 }}>{pessoa.name}</Typography>
            <Typography style={{fontSize: window.innerWidth < 960 && 13}} >{pessoa.job}</Typography>

          </Grid>
          </Grow>

        ))}
      </Grid>
      <Grid ref={refFooter}>
    <Footer />
    </Grid>

    </div>
  )
}
