import React from 'react';
import {Grid, IconButton, Typography} from '@material-ui/core'
import firmaIcon from '../../../assets/firmalogo.svg'
import {Link} from 'react-router-dom';

export default function index({refFooter}) {
  const wid = window.innerWidth
  console.log(window.innerWidth)






  return (
    <Grid container justify='space-between' style={{position: 'fixed',  padding: '0px 5%', zIndex: 1}}>
      <Link style={{textDecoration: 'none', marginLeft: 20}} to=''>
        <img style={{width:  wid * 0.03, height: 'auto'}} src={firmaIcon} alt='firmaIcon' />
      </Link>
      <Grid>
        <Grid container justify='space-between' style={{width: wid * 0.2}}>
        <IconButton><Link style={{textDecoration: 'none'}} to='/sobre'><Typography style={{fontSize: wid * 0.012}}>A Firma</Typography></Link></IconButton>

<IconButton><Link style={{textDecoration: 'none'}} to='/projetos/moda'><Typography style={{fontSize: wid * 0.012}}>Projetos</Typography></Link></IconButton>

  <IconButton onClick={() => {
      refFooter.current.scrollIntoView({
        behavior: 'smooth',
      });

    }}><Typography style={{fontSize: wid * 0.012}}>Contatos</Typography></IconButton>
        </Grid>


      </Grid>
    </Grid>
  )
}
